<template>
  <div
    class="reportCoverNew"
    :style="
      'transform:scale(' +
      percent +
      ');' +
      (percent < 1 ? 'height:' + compHeight + 'px' : '')
    "
    ref="compCover"
  >
    <div class="an-reportCover1">
      <section class="an-reportCover1-bg">
        <img src="@/src/assets/reportCover/one/bg-total.jpg" alt="" />
      </section>

      <edpWaterBg
        percent="20%"
        opacity="0.4"
        itemHeight="160px"
        slot="bgContent"
      />

      <section class="an-reportCover1-content">
        <section class="an-reportCover1-square ars-sq1">
          <img :src="info.memberImage" alt="" class="ars-sq1-memberImage" />

          <div
            class="an-reportCover1-wordCotent rptArea-word1-1 animateWordCs"
            ref="animateWord0"
          >
            {{ info.memberName }}
          </div>

          <div
            class="an-reportCover1-wordCotent rptArea-word1-2 animateWordCs"
            ref="animateWord1"
          >
            {{ $t("reportCover.slide1Title2") }}
          </div>

          <div class="an-reportCover1-wordCotent rptArea-word-largeYear">
            {{ info.thisYear }}

            <img src="@/src/assets/reportCover/one/yearBg.png" alt="" />
          </div>

          <div
            class="an-reportCover1-wordCotent rptArea-word1-3 animateWordCs"
            ref="animateWord2"
          >
            {{ info.thisYear }} {{ $t("reportCover.slide1Title_1") }}<br />
            {{ info.nextYear }} {{ $t("reportCover.slide1Title_2") }}
          </div>

          <div
            class="an-reportCover1-button1 animateWordCs"
            @click="moveToOne"
            ref="animateWord3"
          >
            {{ $t("reportCover.slide1Button") }} {{ info.thisYear }}
          </div>

          <div
            class="an-reportCover1-beginIcon animateWordCs"
            ref="animateWord4"
          ></div>
        </section>

        <!-- art1 -->
        <section class="an-reportCover1-square ars-art1" ref="art1">
          <img
            src="@/src/assets/reportCover/one/title-one.png"
            alt=""
            class="art-titleImg animateWordCs"
            ref="animateWord5"
          />

          <div class="an-reportCover1__conAc animateWordCs" ref="animateWord6">
            <span v-html="$t('reportCover.slide2Content1')"></span>

            <span class="word-spBg">
              {{ info.memberYear }}{{ $t("reportCover.slide2ContentYear") }}
            </span>

            <span v-html="$t('reportCover.slide2Content2')"></span>

            <div class="wdspt1">
              <span v-html="$t('reportCover.slide2Content5')"></span>
              <span class="wordsp-p"
                >{{ info.meetingDurationPercentage || 0 }}%</span
              >
              <span v-html="$t('reportCover.slide2Content6')"></span><br />
              <span class="wordsp-p">{{ info.travelPercentage || 0 }}%</span>
              <span v-html="$t('reportCover.slide2Content7')"></span>
              <span class="wordsp-p"
                >{{ info.routineWorkPercentage || 0 }}%</span
              >
              <span v-html="$t('reportCover.slide2Content8')"></span>

              <section class="wdspt1-chart">
                <div class="wdspt1-chart__chart" id="workPercentChart"></div>

                <ul>
                  <li>
                    <span class="wdspt1-chart__icon"></span>
                    {{ $t("reportCover.chartItemMeeting") }}
                  </li>
                  <li>
                    <span class="wdspt1-chart__icon"></span>
                    {{ $t("reportCover.chartItemTravel") }}
                  </li>
                  <li>
                    <span class="wdspt1-chart__icon"></span>
                    {{ $t("reportCover.chartItemDaily") }}
                  </li>
                </ul>
              </section>
            </div>

            <p
              class="an-reportCover1__conAc__detail"
              :class="{ 'an-op0': !info.memberWork }"
            >
              <span v-html="$t('reportCover.slide2Content4')"></span>

              <span class="wordsp2">
                <span v-if="info.memberWork" v-html="info.memberWork"></span>
                <span v-else>&nbsp;</span>
              </span>
            </p>
          </div>
        </section>
        <!-- art1 end -->

        <!-- art2 -->
        <section class="an-reportCover1-square ars-art2">
          <img
            src="@/src/assets/reportCover/one/title-two.png"
            alt=""
            class="art-titleImg animateWordCs"
            ref="animateWord7"
          />

          <div class="an-reportCover1__conAc animateWordCs" ref="animateWord8">
            <p>
              <span v-html="$t('reportCover.slide3Content2')"></span>
              <span class="wordsp-p">{{ info.memberTimeNum || 0 }}</span>
              <span v-html="$t('reportCover.slide3Content3')"></span>
              <span class="wordsp-p">{{ info.memberTimePerson || 0 }}</span>
              <span v-html="$t('reportCover.slide3Content4')"></span>

              <span
                v-html="$t('reportCover.slide3Content1')"
                :class="{ 'an-op0': !info.memberDay }"
              ></span>
              <span :class="{ 'an-op0': !info.memberDay }">{{
                info.memberDay
              }}</span>

              <span
                class="wordsp-p"
                :class="{ 'an-op0': !info.memberDay || !info.memberTime }"
                >{{ " " + info.memberTime }}</span
              >
            </p>

            <p class="wdspt2">
              <span v-html="$t('reportCover.slide3Content8')"></span>
            </p>

            <div
              class="wdspt3"
              :class="{
                'an-op0':
                  !info.empYearMeetingCommons ||
                  info.empYearMeetingCommons.length == 0,
              }"
            >
              <img
                :src="info.meetingCommonPicture || defaultHeadImage"
                alt=""
              />

              <span class="wordsp-p">
                {{
                  this.$i18n.locale === "cn"
                    ? info.meetingCommonName || info.meetingCommonEnglishName
                    : info.meetingCommonEnglishName || info.meetingCommonName
                }}</span
              >

              <span v-html="$t('reportCover.slide3Content9')"></span>

              <a href="javascript:void(0);" @click="memberDialogShow">{{
                $t("reportCover.slide3Content10")
              }}</a>
            </div>

            <p class="wdspt1">
              <span v-html="$t('reportCover.slide3Content5')"></span>
            </p>

            <p class="wordsp2">
              <span v-html="$t('reportCover.slide3Content6')"></span>
              <br />
              <span v-html="$t('reportCover.slide3Content7')"></span>
            </p>
          </div>
        </section>
        <!-- art2 end -->

        <!-- art3 -->
        <section class="an-reportCover1-square ars-art3">
          <img
            src="@/src/assets/reportCover/one/title-three.png"
            alt=""
            class="art-titleImg animateWordCs"
            ref="animateWord9"
          />

          <div class="an-reportCover1__conAc animateWordCs" ref="animateWord10">
            <p>
              <span v-html="$t('reportCover.slide4Content1New')"></span>
              <span class="wordsp-p">{{ info.memberOutNum || 0 }}</span>
              <span v-html="$t('reportCover.slide4Content2New')"></span>
            </p>

            <p
              class="wdspt2"
              :class="{ 'an-op0': !info.memberOutCity || !info.memberOutDay }"
            >
              <span v-html="$t('reportCover.slide4Content3New')"></span>
              <span class="wordsp-p">{{ info.memberOutCity }}</span>
              <span v-html="$t('reportCover.slide4Content4New')"></span>
              <span class="wordsp-p">{{ info.memberOutDay }}</span>
              <span v-html="$t('reportCover.slide4Content5New')"></span>
            </p>

            <p class="wdspt3" :class="{ 'an-op0': !info.businessCity }">
              <span v-html="$t('reportCover.slide4Content6New')"></span>
            </p>

            <p :class="{ 'an-op0': !info.businessCity }">
              <span class="wordsp-p">{{ info.businessCity }}</span>
            </p>

            <p class="wdspt1">
              <span v-html="$t('reportCover.slide4Content7')"></span>
            </p>

            <p class="wordsp2">
              <span v-html="$t('reportCover.slide4Content8')"></span>
              <br />
              <span v-html="$t('reportCover.slide4Content9')"></span>
            </p>
          </div>
        </section>
        <!-- art3 end -->

        <!-- art4 -->
        <section class="an-reportCover1-square ars-art4">
          <img
            src="@/src/assets/reportCover/one/title-four.png"
            alt=""
            class="art-titleImg animateWordCs"
            ref="animateWord11"
          />

          <div class="an-reportCover1__conAc animateWordCs" ref="animateWord12">
            {{ $i18n.locale === "cn" ? "" : "In " }}{{ info.slideSpYear }}

            <p>
              <span v-html="$t('reportCover.slide5Content1New')"></span>
              <span class="wordsp-p">{{ info.memberProgramNum || 0 }}</span>
              <span v-html="$t('reportCover.slide5Content2New')"></span>
            </p>

            <p class="wdspt1">
              <span v-html="$t('reportCover.slide5Content3New')"></span>
              <span class="wordsp-p">{{
                info.slideSpDay ? info.slideSpDay : 0
              }}</span>
              <span v-html="$t('reportCover.slide5Content2')"></span>

              <span class="wordsp-p">{{
                info.slideSpHour ? info.slideSpHour : 0
              }}</span>
              <span v-html="$t('reportCover.slide5Content3')"></span>
            </p>

            <p>
              <span v-html="$t('reportCover.slide5Content4')"></span>
              <span class="wordsp-p">{{ info.slideSpPercent }}</span>
              <span v-html="$t('reportCover.slide5Content5')"></span>
            </p>

            <p class="wdspt2">
              <span :class="{ 'an-op0': !info.slideSpDate }">
                <span>{{ info.slideSpDate }}</span>
                <span v-html="$t('reportCover.slide5Content6')"></span>
                <span class="wordsp-p wordsp-p-normal">{{
                  info.slideSpInTime
                }}</span>
                <span v-html="$t('reportCover.slide5Content7')"></span>
              </span>

              <span class="reportMbBr"><br /></span>

              <span :class="{ 'an-op0': !info.slideSpDate2 }">
                <span>{{ info.slideSpDate2 }}</span>
                <span v-html="$t('reportCover.slide5Content8')"></span>
                <span class="wordsp-p wordsp-p-normal">{{
                  info.slideSpOutTime
                }}</span>
                <span v-html="$t('reportCover.slide5Content9')"></span>
              </span>
            </p>

            <span class="reportMbBr"><br /></span>

            <p><span v-html="$t('reportCover.slide5Content10')"></span></p>

            <p class="wordsp2">
              <span
                v-html="$t('reportCover.slide5Content11')"
                style="margin-left: 0"
              ></span>
              <br />
              <span v-html="$t('reportCover.slide5Content12')"></span>
            </p>
          </div>
        </section>
        <!-- art4 end -->

        <!-- art5 -->
        <section class="an-reportCover1-square ars-art5">
          <img
            src="@/src/assets/reportCover/one/title-five.png"
            alt=""
            class="art-titleImg animateWordCs"
            ref="animateWord13"
          />

          <div class="an-reportCover1__conAc animateWordCs" ref="animateWord14">
            {{ $i18n.locale === "cn" ? "" : "In " }}{{ info.slideSpYear }}

            <span v-html="$t('reportCover.slide6Content1_1')"></span>

            <span class="reportMbBr"><br /></span>

            <span v-html="$t('reportCover.slide6Content1_2')"></span>

            <span class="wordsp-p">{{ info.relateActive || 0 }}</span>
            <span v-html="$t('reportCover.slide6Content2')"></span>

            <section
              class="edp-reportCover__activePimg"
              v-if="info.relateList && info.relateList.length > 0"
            >
              <img
                v-for="(item, index) in info.relateList"
                alt=""
                :key="'reportCover__activePimg' + index"
                :src="item.image"
              />
            </section>

            <section
              class="edp-reportCover__activePimg"
              :class="{
                'an-op0': !info.relateList || info.relateList.length == 0,
              }"
              v-else
            >
              <img :src="defaultHeadImage" alt="" />
            </section>

            <p
              class="wdspt1"
              :class="{
                'an-op0': !info.relateList || info.relateList.length == 0,
              }"
              v-if="$i18n.locale === 'en'"
            >
              <span v-html="$t('reportCover.slide6Content4')"></span>

              <span class="wordsp-p">{{ info.relateActive2 }}</span>

              <span v-html="$t('reportCover.slide6Content5')"></span>

              <br />

              <span v-html="$t('reportCover.slide6Content3')"></span>

              <span
                v-for="(item, index) in info.relateList"
                :key="'reportCover_activePerson' + index"
              >
                {{ item.name + " " }}
              </span>
            </p>

            <p
              class="wdspt1"
              :class="{
                'an-op0': !info.relateList || info.relateList.length == 0,
              }"
              v-if="$i18n.locale === 'cn'"
            >
              <span v-html="$t('reportCover.slide6Content3')"></span>

              <span
                v-for="(item, index) in info.relateList"
                :key="'reportCover_activePerson' + index"
              >
                {{ item.name + " " }}
              </span>

              <br />

              <span v-html="$t('reportCover.slide6Content4')"></span>

              <span class="wordsp-p">{{ info.relateActive2 }}</span>

              <span v-html="$t('reportCover.slide6Content5')"></span>
            </p>

            <div
              class="wdspt2"
              :class="{
                'an-op0': !info.relateRecommend,
              }"
            >
              <span v-html="$t('reportCover.slide6Content6')"></span>
              <span class="wordsp-p">{{ info.relateRecommend }}</span>
              <span v-html="$t('reportCover.slide6Content7')"></span>
              <span v-html="$t('reportCover.slide6Content8')"></span>
            </div>
          </div>
        </section>
        <!-- art5 end -->

        <section
          class="an-reportCover1-square ars-sq2 animateWordCs"
          ref="animateWord15"
        >
          <div class="ars-sq2__opacity"></div>

          <div class="ars-sq2__content">
            <div class="wdspt1">
              <p>{{ $i18n.locale === "cn" ? "" : "In " }}{{ info.thisYear }}</p>

              <p v-html="$t('reportCover.slide7Content1_1')"></p>
            </div>

            <div class="wdspt2">
              <p>
                {{ $i18n.locale === "cn" ? "" : "For " }}{{ info.nextYear }}
              </p>

              <p v-html="$t('reportCover.slide7Content1_2')"></p>
            </div>
          </div>
        </section>
      </section>
    </div>

    <edp-dialog ref="reportDialog" class="reportCover1-dialog" overlayShow="no">
      <div slot="content" class="reportCover1-dialog__article">
        <section class="reportCover1-article__title">
          {{ $t("reportCover.dialogMemberTitle") }}
        </section>

        <ul>
          <li
            v-for="(item, index) in info.empYearMeetingCommons"
            :key="'empYearMeetingCommons' + index"
          >
            <div class="reportCover1-article__detail">
              <section class="reportCover1-article__detail__img">
                <img
                  :src="item.meetingCommonPicture || defaultHeadImage"
                  alt=""
                />
              </section>

              <section class="reportCover1-article__detail__content">
                <div class="reportCover1-article__detail__title">
                  {{
                    $i18n.locale === "cn"
                      ? item.meetingCommonCname || item.meetingCommonEname
                      : item.meetingCommonEname || item.meetingCommonCname
                  }}

                  <em v-if="item.meetingCommonGenerations"
                    ><span>{{ item.meetingCommonGenerations }}</span></em
                  >
                </div>

                <p>
                  {{
                    $i18n.locale === "cn"
                      ? item.meetingCommonJobPositionCn ||
                        item.meetingCommonJobPosition
                      : item.meetingCommonJobPosition ||
                        item.meetingCommonJobPositionCn
                  }}
                </p>
              </section>
            </div>
          </li>
        </ul>
      </div>
    </edp-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";

export default {
  name: "reportCover1",
  props: ["info", "percent"],
  data() {
    return {
      compHeight: "auto",
      pagePercent: 1,
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  methods: {
    moveToOne() {
      let art1Top = this.$refs.art1.offsetTop;

      $("html, body").animate({ scrollTop: art1Top + "px" }, 500);
    },
    memberDialogShow() {
      this.$refs.reportDialog.show();
    },
    moveAni() {
      let _this = this;
      let windowScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      for (let i = 0; i <= 15; i++) {
        let evTop = _this.$refs["animateWord" + i].offsetTop;

        let _style = window.getComputedStyle(_this.$refs["animateWord" + i]);

        let mTop = _style.getPropertyValue("margin-top") || 0;
        let pTop = _style.getPropertyValue("padding-top") || 0;

        let dispd = 0;

        if (i == 15) {
          dispd = (evTop + parseInt(mTop) - 600) * this.percent;
        } else {
          dispd =
            (evTop + parseInt(mTop) + parseInt(pTop) - 430) * this.percent;
        }

        if (windowScrollTop >= dispd) {
          _this.$refs["animateWord" + i].classList.add("fadeMoveCs");
        } else {
          _this.$refs["animateWord" + i].classList.remove("fadeMoveCs");
        }
      }
    },
    heightCalc() {
      this.$emit("resize");
    },
  },
  mounted() {
    let _this = this;

    var chartDom = document.getElementById("workPercentChart");
    var myChart = echarts.init(chartDom);
    var chartOption = {
      series: [
        {
          type: "pie",
          radius: ["30%", "80%"],
          roseType: "radius",
          label: {
            show: true,
            position: "inside",
            formatter: (val) => {
              return val.data.value + "%";
            },

            color: "#000",
          },
          itemStyle: {
            borderColor: "rgba(255, 255, 255, 0.56)",
            borderWidth: 1,
            borderRadius: 4,
          },
          data: [
            {
              value: _this.info.meetingDurationPercentage || 0,
              itemStyle: { color: "#44C9C1" },
            },
            {
              value: _this.info.travelPercentage || 0,
              itemStyle: { color: "#6C9CB7" },
            },
            {
              value: _this.info.routineWorkPercentage || 0,
              itemStyle: { color: "#FAC858" },
            },
          ],
        },
      ],
    };

    myChart && myChart.setOption(chartOption);

    setTimeout(() => {
      this.moveAni();

      this.compHeight = (this.$refs.compCover.clientHeight - 80) * this.percent;
      window.addEventListener("scroll", this.moveAni);
      window.addEventListener("resize", this.heightCalc);
    }, 100);
  },
  destroyed() {
    window.removeEventListener("scroll", this.moveAni);
    window.removeEventListener("resize", this.heightCalc);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

@keyframes fadeMove {
  0% {
    opacity: 0;
    top: toPc(100);
  }
  100% {
    opacity: 1;
    top: toPc(0);
  }
}

.reportCoverNew {
  width: toPc(1366);
  transform-origin: 0 0;
}
.animateWordCs {
  position: relative;
  top: toPc(100);
  opacity: 0;
  transition: all 1s;
}
.fadeMoveCs {
  // animation: fadeMove 1s ease forwards;
  opacity: 1;
  top: toPc(0);
}

.an-reportCover1 {
  position: relative;
  width: 100%;
  color: #fff;
  margin-top: toPc(-80);

  .an-op0 {
    opacity: 0 !important;
  }
}
.an-reportCover1-bg {
  position: relative;
  width: 100%;
  z-index: 0;

  img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }
}
.an-reportCover1-content {
  position: absolute;
  width: 100%;
  z-index: 2;
  left: 0;
  top: toPc(90);
}
.an-reportCover1-square {
  width: 100%;

  &.ars-sq1 {
    padding-top: toPc(73);
    text-align: center;

    .ars-sq1-memberImage {
      position: relative;
      display: block;
      margin: 0 auto;
      width: toPc(107);
      height: toPc(107);
      border: toPc(3) solid rgba(255, 255, 255, 0.6);
      border-radius: 100%;
    }
  }

  &.ars-art1 {
    padding-top: toPc(160);

    .an-reportCover1__conAc {
      padding-top: toPc(80);
    }
    .wdspt1 {
      position: relative;
      width: 100%;
      padding-top: toPc(30);
      z-index: 1;

      .wdspt1-chart {
        display: flex;
        width: toPc(400);
        height: toPc(190);
        position: absolute;
        right: toPc(-200);
        bottom: toPc(-20);

        .wdspt1-chart__chart {
          width: toPc(200);
          height: toPc(190);
          flex-shrink: 0;
        }
        ul {
          display: flex;
          flex-basis: 100%;
          flex-direction: column;
          justify-content: center;
          font-size: toPc(12);

          li {
            display: flex;
            width: 100%;
            align-items: center;
            margin: toPc(3) 0;

            .wdspt1-chart__icon {
              display: inline-block;
              width: toPc(6);
              height: toPc(6);
              border-radius: 2px;
              margin-right: toPc(8);
              flex-shrink: 0;
            }

            &:nth-of-type(1) {
              .wdspt1-chart__icon {
                background: #44c9c1;
              }
            }
            &:nth-of-type(2) {
              .wdspt1-chart__icon {
                background: #6c9cb7;
              }
            }
            &:nth-of-type(3) {
              .wdspt1-chart__icon {
                background: #fac858;
              }
            }
          }
        }
      }
    }
    .wordsp2 {
      display: block;
      font-weight: bold;
      font-size: toPc(42);
      color: #6d5b3d;
      padding-top: toPc(5);
    }
  }

  &.ars-art2 {
    padding-top: toPc(173);

    .an-reportCover1__conAc {
      padding-top: toPc(109);
      text-align: right;
    }

    .wdspt1 {
      padding-top: toPc(40);
    }
    .wdspt2 {
      padding-top: toPc(24);
    }
    .wdspt3 {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      margin-top: toPc(10);

      img {
        display: block;
        border-radius: 100%;
        width: toPc(60);
        height: toPc(60);
        margin-right: toPc(18);
      }
      .wordsp-p {
        margin-right: toPc(6);
      }
      a:link,
      a:visited {
        display: inline-block;
        padding: toPc(10) toPc(20);
        line-height: 1;
        font-size: toPc(20);
        color: #fff;
        background: #b79663;
        border-radius: toPc(36);
        cursor: pointer;
        margin-left: toPc(15);
      }
    }
    .wordsp2 {
      color: #603c02;
      font-weight: bold;
      font-size: toPc(38);
      padding-top: toPc(15);
      line-height: 1.2;
    }
  }

  &.ars-art3 {
    padding-top: toPc(216);

    .an-reportCover1__conAc {
      padding-top: toPc(94);
    }
    .wordsp-p {
      color: #57451c;
    }
    .wdspt2 {
      padding-top: toPc(29);
    }
    .wdspt3 {
      padding-top: toPc(10);
    }
    .wdspt1 {
      padding-top: toPc(60);
    }
    .wordsp2 {
      color: #defff3;
      font-weight: bold;
      font-size: toPc(48);
      padding-top: toPc(24);
      line-height: 1.2;
    }
  }

  &.ars-art4 {
    padding-top: toPc(216);

    .an-reportCover1__conAc {
      padding-top: toPc(108);
      text-align: right;
    }

    .wdspt1 {
      padding-top: toPc(35);
    }
    .wdspt2 {
      padding-top: toPc(35);
    }
    .wordsp2 {
      color: #0e5038;
      font-weight: bold;
      font-size: toPc(48);
      padding-top: toPc(24);
      line-height: 1.2;
    }
  }

  &.ars-art5 {
    padding-top: toPc(320);
    font-size: toPc(33);

    .an-reportCover1__conAc {
      padding-top: toPc(130);
    }
    .wdspt1 {
      opacity: 0.7;
    }
    .wdspt2 {
      padding-top: toPc(38);
      font-size: toPc(37);
    }

    .edp-reportCover__activePimg {
      width: 100%;
      padding: toPc(48) 0 toPc(58) 0;
      overflow: hidden;

      img {
        display: block;
        float: left;
        margin-right: toPc(14);
        width: toPc(126);
        height: toPc(126);
        border-radius: 100%;
      }
    }
  }

  &.ars-sq2 {
    width: toPc(1040);
    margin: 0 auto;
    margin-top: toPc(198);
    padding: toPc(75) toPc(60);
    text-align: center;
    color: #503812;
    font-size: toPc(40);

    .ars-sq2__opacity {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: #fff;
      opacity: 0.28;
      left: 0;
      top: 0;
    }
    .wdspt2 {
      padding-top: toPc(90);
    }
  }
}

.art-titleImg {
  display: block;
  width: auto;
  height: toPc(133);
  margin: 0 auto;
}
.an-reportCover1__conAc {
  width: toPc(980);
  margin: 0 auto;
  color: #000;
  font-size: toPc(26);
  line-height: 1.4;
}
.word-spBg {
  display: inline-block;
  // padding: toPc(10) toPc(36);
  font-weight: bold;
  color: #fff;
  font-size: toPc(42);
  /*background: url("~@/src/assets/reportCover/one/wordsp-bg1.png") no-repeat
    center center;
  background-size: toPc(220) toPc(84);*/
  margin-left: toPc(10);
  // min-width: toPc(220);
  // text-align: center;
}
.wordsp-p {
  font-size: toPc(36);
  color: #3e886d;
  font-weight: bold;

  &.wordsp-p-normal {
    font-weight: normal;
  }
}

.an-reportCover1-wordCotent {
  width: 100%;
  text-align: center;
  line-height: 1.5;

  &.rptArea-word1-1 {
    font-size: toPc(18);
    padding-top: toPc(22);
  }
  &.rptArea-word1-2 {
    font-size: toPc(36);
    font-weight: bold;
    padding-top: toPc(12);
    line-height: 1;
  }
  &.rptArea-word1-3 {
    font-size: toPc(20);
    margin-top: toPc(-100);
  }
  &.rptArea-word-largeYear {
    position: relative;
    font-size: toPc(360);
    font-family: JLREmeric;
    font-weight: normal;
    margin-top: toPc(-100);
    letter-spacing: toPc(-40);
    opacity: 0.8;
    line-height: 1.5;

    img {
      position: absolute;
      width: toPc(780);
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.an-reportCover1-button1 {
  position: relative;
  display: inline-flex;
  min-width: toPc(336);
  font-size: toPc(25);
  padding: toPc(12) toPc(19);
  font-weight: bold;
  background: url("~@/src/assets/reportCover/one/beginButton.jpg") no-repeat
    center center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  margin-top: toPc(18);
  line-height: 1;
  // cursor: pointer;
}
.an-reportCover1-beginIcon {
  position: relative;
  width: toPc(37);
  height: toPc(21);
  overflow: hidden;
  margin: toPc(24) auto;
  background: url("~@/src/assets/reportCover/one/beginIconDown.png") no-repeat
    center center;
  background-size: cover;
}

.reportCover1-dialog {
  width: toPc(985) !important;
  background: linear-gradient(
    274.27deg,
    rgba(255, 255, 255, 0.69) 0.14%,
    rgba(255, 255, 255, 0.26) 46.74%,
    rgba(255, 255, 255, 0.78) 96.36%,
    rgba(255, 255, 255, 0.43) 96.36%
  );
  border: 4px solid rgba(255, 255, 255, 0.47);
  backdrop-filter: blur(17.5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: toPc(20);

  .reportCover1-dialog__article {
    width: 100%;
    padding-bottom: toPc(30);
  }
  .reportCover1-article__title {
    color: #000;
    width: 100%;
    text-align: center;
    font-size: toPc(26);
    font-weight: bold;
    padding-bottom: toPc(10);
  }
  ul {
    display: flex;
    width: 100%;
    max-height: 60vh;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;

    li {
      width: 33.33%;
      padding: toPc(25) toPc(25) 0 toPc(25);

      &:first-child {
        .reportCover1-article__detail__img {
          &::after {
            display: block;
            position: absolute;
            top: toPc(-20);
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            content: "";
            width: toPc(25);
            height: toPc(23);
            background: url("~@/src/assets/rank/rank-one.png") no-repeat 0 0;
            background-size: 100% 100%;
          }
          img {
            border: 2px solid #feb64e;
          }
        }
      }
    }
    .reportCover1-article__detail {
      display: flex;
      width: 100%;
    }
    .reportCover1-article__detail__img {
      position: relative;
      width: toPc(55);
      height: toPc(55);
      flex-shrink: 0;

      img {
        display: block;
        position: relative;
        width: toPc(55);
        height: toPc(55);
        border: 2px solid #ffffff;
        border-radius: 100%;
      }
    }
    .reportCover1-article__detail__content {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: toPc(15);
    }
    .reportCover1-article__detail__title {
      display: flex;
      font-size: toPc(18);
      align-items: center;

      em {
        display: inline-block;
        color: #fff;
        border-radius: toPc(8) 0px;
        background: linear-gradient(90.61deg, #60cfc6 3.65%, #61bfb4 99.54%);
        padding: toPc(2) toPc(8);
        text-align: center;
        margin-left: toPc(8);
        font-style: normal;
        border: 1px solid rgba(152, 244, 235, 0.6);
        line-height: toPc(16);
        transform: scale(0.86);

        span {
          font-size: toPc(14);
          transform: scale(0.86);
        }
      }
    }
    p {
      color: rgba(0, 0, 0, 0.5);
      padding-top: toPc(4);
      font-size: toPc(12);
      line-height: 1.4;
    }
  }
}
</style>
