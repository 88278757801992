<template>
  <div
    class="reportCoverNew-comp"
    :class="{ 'reportCoverNew-comp-flex': pageTemplate == 2 }"
  >
    <edp-header-search
      :headerLeftWord="$t('reportCover.headerLeftWord')"
      noSearch="true"
    ></edp-header-search>

    <div class="reportCoverNew-out">
      <reportCover1
        :info="info"
        :percent="pagePercent"
        v-if="pageTemplate == 1"
        @resize="comp1ResizeFn"
      />

      <reportCover2
        :info="info"
        :percent="pagePercent"
        v-if="pageTemplate == 2"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import reportCover1 from "../components/reportCover/reportCover1.vue";
import reportCover2 from "../components/reportCover/reportCover2.vue";

export default {
  components: { reportCover1, reportCover2 },
  name: "reportCover",
  data() {
    return {
      pageTemplate: "",
      pagePercent: 1,
      info: {
        thisYear: "",
        nextYear: "",

        memberImage: "",
        memberName: "",
        memberYear: 0,
        memberMonth: 0,
        memberWork: "",
        memberDay: "",
        memberTime: "",
        memberTimeNum: 0,
        memberTimePerson: 0,
        memberProgramNum: 0,
        memberOutNum: 0,
        memberOutCity: "",
        memberOutDay: 0,

        slideSpYear: "",
        slideSpDay: "",
        slideSpHour: "",
        slideSpPercent: "",
        slideSpDate: "",
        slideSpInTime: "",
        slideSpDate2: "",
        slideSpOutTime: "",

        relateYear: 0,
        relateActive: 0,
        relateList: [],
        relateActive2: 0,
        relateRecommend: 0,
      },

      iconOut: "",
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
      page_platform: (state) => state.login.page_platform,
    }),
  },
  methods: {
    ...mapActions({
      reportCoverDetail: "reportCover/reportCoverDetail",
      appReportAnDetail: "reportApp/appReportAnDetail",
    }),

    numToMonth(data) {
      let result = "";

      let prsData = data ? parseInt(data) : "";

      if (this.$i18n.locale === "cn") {
        prsData ? (result = prsData + " 月") : (result = "");
      } else {
        switch (prsData) {
          case 1:
            result = "January";
            break;
          case 2:
            result = "February";
            break;
          case 3:
            result = "March";
            break;
          case 4:
            result = "April";
            break;
          case 5:
            result = "May";
            break;
          case 6:
            result = "June";
            break;
          case 7:
            result = "July";
            break;
          case 8:
            result = "August";
            break;
          case 9:
            result = "September";
            break;
          case 10:
            result = "October";
            break;
          case 11:
            result = "November";
            break;
          case 12:
            result = "December";
            break;
        }
      }

      return result;
    },

    async pageInit() {
      this.iconOut = this.$i18n.locale === "cn" ? "" : "-";

      let resData = {};

      if (this.$route.name === "reportApp_annual") {
        let params = { currentYear: this.$route.query.currentYear };
        let res = await this.appReportAnDetail(params);

        resData = res.data.data;
      } else {
        let res = await this.reportCoverDetail(this.$route.query.onlyId);

        resData = res.data.data;
      }

      this.pageTemplate = 1; //resData.templateExample;

      this.info.meetingDurationPercentage = resData.meetingDurationPercentage;
      this.info.travelPercentage = resData.travelPercentage;
      this.info.routineWorkPercentage = resData.routineWorkPercentage;

      this.info.meetingCommonCdsid = resData.meetingCommonCdsid;
      this.info.meetingCommonName = resData.meetingCommonName;
      this.info.meetingCommonEnglishName = resData.meetingCommonEnglishName;
      this.info.meetingCommonPicture = resData.meetingCommonPicture;
      this.info.empYearMeetingCommons = resData.empYearMeetingCommons;

      this.info.businessCity = resData.businessCity;

      this.info.thisYear = resData.currentYear;
      this.info.nextYear = resData.nextYeat;
      this.info.memberName =
        this.$i18n.locale === "cn"
          ? resData.cname || resData.ename
          : resData.ename || resData.cname;

      this.info.memberImage =
        resData.employeeProfilePicture || this.defaultHeadImage;

      this.info.memberYear = resData.yearInCompany;
      this.info.memberMonth = resData.jobChangeMonth;
      this.info.memberWork = resData.changePosition;

      this.info.memberDay = resData.lastMeetingTime
        ? this.utils.getTimeFn(resData.lastMeetingTime, "date", this.iconOut)
        : "";
      this.info.memberTime = resData.lastMeetingTime
        ? this.utils.getTimeFn(resData.lastMeetingTime, "time", this.iconOut)
        : "";

      this.info.memberTimeNum = resData.initiatedMeetingNum;
      this.info.memberTimePerson = resData.invitedMeetingNum;

      this.info.memberProgramNum = resData.projectParticipatedNumOneYear;
      this.info.memberOutNum = resData.businessTripNumOneYear;
      this.info.memberOutCity = resData.businessCity1rd;
      this.info.memberOutDay = resData.businessCity1rdDays;

      this.info.slideSpYear = resData.currentYear;
      this.info.slideSpDay = resData.onsiteWorkingDays;
      this.info.slideSpHour = resData.onsiteWorkingHours;
      this.info.slideSpPercent = (resData.onsiteWorkingRankPercent || 0) + "%";

      this.info.slideSpDate = resData.longestWorkingOnTime
        ? this.utils.getTimeFn(
            resData.longestWorkingOnTime,
            "noYearNoTime",
            this.iconOut
          )
        : "";

      this.info.slideSpInTime = resData.longestWorkingOnTime
        ? this.utils.getTimeFn(
            resData.longestWorkingOnTime,
            "time",
            this.iconOut
          )
        : "";

      this.info.slideSpDate2 = resData.longestWorkingOffTime
        ? this.utils.getTimeFn(
            resData.longestWorkingOffTime,
            "noYearNoTime",
            this.iconOut
          )
        : "";

      this.info.slideSpOutTime = resData.longestWorkingOffTime
        ? this.utils.getTimeFn(
            resData.longestWorkingOffTime,
            "time",
            this.iconOut
          )
        : "";

      this.info.relateActive = resData.edcNums;

      if (resData.empYearEdcCommons) {
        this.info.relateList = resData.empYearEdcCommons.map((val) => {
          return {
            name:
              this.$i18n.locale === "cn"
                ? val.edcCommonCname || val.edcCommonEname
                : val.edcCommonEname || val.edcCommonCname,
            image: val.edcCommonPicture || this.defaultHeadImage,
          };
        });
      }

      this.info.relateActive2 = resData.meetingCommonNums;
      this.info.relateRecommend = resData.employeeInterpolateNums;
    },

    sizePercent() {
      let windowWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      this.pagePercent = windowWidth / 1366 - 0.001;
    },
    comp1ResizeFn() {
      if (this.page_platform === "windows") {
        this.pageTemplate = -1;

        setTimeout(() => {
          this.pageTemplate = 1;
        }, 100);
      }
    },
  },

  mounted() {
    this.pageInit();
    this.sizePercent();

    window.addEventListener("resize", this.sizePercent);

    $("body").addClass("bodyXnone");
    $("html").addClass("bodyXnone");
  },
  destroyed() {
    window.removeEventListener("resize", this.sizePercent);
    $("body").removeClass("bodyXnone");
    $("html").removeClass("bodyXnone");
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.reportCoverNew-comp {
  width: 100%;

  .reportCoverNew-out {
    position: relative;
    width: 100%;
  }

  &.reportCoverNew-comp-flex {
    display: flex;
    flex-direction: column;
    height: 100%;

    .reportCoverNew-out {
      flex-basis: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.bodyXnone {
  width: 100vw;
  overflow-x: hidden;
}
</style>
