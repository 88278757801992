<template>
  <div class="an-reportCover2">
    <swiper
      :options="swiperOptions"
      :ref="'mySwiper'"
      @slideChangeTransitionEnd="onSlideChange"
    >
      <!--first-->
      <swiper-slide>
        <div class="reportBlank-slide">
          <div class="reportBlank-bg">
            <img
              src="@/src/assets/reportCover/two/sq-first.png"
              class="reportBlank-relativeBg"
              alt=""
            />

            <edpWaterBg
              percent="20%"
              opacity="0.4"
              itemHeight="160px"
              slot="bgContent"
            />
          </div>

          <section class="reportBlank-commonContent reportBlank-firstContent">
            <div class="reportBlank-center">
              <section
                class="an-reportCover2__conAc"
                :class="{ selected: activeIndex == 0 }"
              >
                <section class="reportBlank-firstContent-year">
                  <span>{{ info.thisYear.toString().split("")[0] }}</span>
                  <span>{{ info.thisYear.toString().split("")[1] }}</span>
                  <span>{{ info.thisYear.toString().split("")[2] }}</span>
                  <span>{{ info.thisYear.toString().split("")[3] }}</span>
                </section>

                <section
                  class="reportBlank-firstContent-title"
                  :class="{ 'cn-sp': $i18n.locale === 'cn' }"
                >
                  <div
                    class="reportBlank-firstContent-wordDetail"
                    v-html="$t('reportCover.reportCover2Title')"
                  ></div>
                  <!--<div class="reportBlank-firstContent-sp">
                    {{ info.memberName }} {{ $t("reportCover.slide1Title2") }}
                  </div>-->
                </section>

                <section class="reportBlank-firstContent-headImage">
                  <img :src="defaultHeadImage" alt="" />

                  <div class="reportBlank-firstContent-headImage__detail">
                    <p>{{ info.memberName }}</p>
                    <p>{{ $t("reportCover.slide1Title2") }}</p>
                  </div>
                </section>

                <section class="reportBlank-firstContent-bottomWord">
                  <div class="reportBlank-firstContent-bottomWord-sq">
                    {{ info.thisYear }} {{ $t("reportCover.slide1Title_1") }}
                  </div>
                  <div class="reportBlank-firstContent-bottomWord-sq">
                    {{ info.nextYear }} {{ $t("reportCover.slide1Title_2") }}
                  </div>
                </section>

                <section class="reportBlank-firstContent-bottomLine">
                  <a
                    href="javascript:void(0);"
                    class="an-reportCover2-mainButton"
                    @click="slideToNext"
                  >
                    {{ $t("reportCover.slide1Button") }}
                  </a>
                </section>
              </section>
            </div>
          </section>
        </div>
      </swiper-slide>
      <!--first end-->

      <!--1-->
      <swiper-slide>
        <div class="reportBlank-slide">
          <div class="reportBlank-bg">
            <img
              src="@/src/assets/reportCover/two/bg.png"
              class="reportBlank-relativeBg"
              alt=""
            />

            <edpWaterBg
              percent="20%"
              opacity="0.4"
              itemHeight="160px"
              slot="bgContent"
            />

            <img
              src="@/src/assets/reportCover/two/circle-bg.png"
              class="reportBlank-relativeBg2"
              alt=""
            />

            <img
              src="@/src/assets/reportCover/two/art1.png"
              class="reportBlank-relativeBg3"
              :class="{ selected: activeIndex == 1 }"
              alt=""
            />

            <div class="reportBlank-relativeBg4">
              <label
                ><span>{{ info.thisYear.toString().split("")[0] }}</span>
                <span>{{ info.thisYear.toString().split("")[1] }}</span>
                <span>{{ info.thisYear.toString().split("")[2] }}</span>
                <span>{{ info.thisYear.toString().split("")[3] }}</span></label
              >
              <div
                class="reportBlank-relativeBg4-detail"
                v-html="$t('reportCover.reportCover2Title')"
              ></div>
            </div>
          </div>

          <section class="reportBlank-commonContent reportBlank-detailContent1">
            <div class="reportBlank-center">
              <section
                class="an-reportCover2__conAc"
                :class="{ selected: activeIndex == 1 }"
              >
                <span
                  class="wordsp"
                  v-html="$t('reportCover.slide2Content1')"
                ></span>

                <span class="wordsp">
                  {{ info.memberYear }}{{ $t("reportCover.slide2ContentYear") }}
                </span>

                <span
                  class="wdspt1"
                  v-html="$t('reportCover.slide2Content2')"
                ></span>

                <p v-if="info.memberWork">
                  <span v-html="$t('reportCover.slide2Content4')"></span>

                  <span class="wordsp2">
                    <span v-html="info.memberWork"></span>
                  </span>
                </p>

                <p class="wdspt2">
                  <span v-html="$t('reportCover.slide2Content5')"></span>
                  <span class="wordsp-p"
                    >{{ info.meetingDurationPercentage || 0 }}%</span
                  >
                  <span v-html="$t('reportCover.slide2Content6')"></span><br />
                  <span class="wordsp-p"
                    >{{ info.travelPercentage || 0 }}%</span
                  >
                  <span v-html="$t('reportCover.slide2Content7')"></span>
                  <span class="wordsp-p"
                    >{{ info.routineWorkPercentage || 0 }}%</span
                  >
                  <span v-html="$t('reportCover.slide2Content8')"></span>
                </p>

                <div class="reportBlank-btnLine">
                  <a
                    href="javascript:void(0);"
                    class="an-reportCover2-mainButton"
                    @click="slideToNext"
                  >
                    {{ $t("reportCover.nextBtn") }}
                  </a>
                </div>
              </section>
            </div>
          </section>
        </div>
      </swiper-slide>
      <!--1 end-->

      <!--2-->
      <swiper-slide>
        <div class="reportBlank-slide">
          <div class="reportBlank-bg">
            <img
              src="@/src/assets/reportCover/two/bg.png"
              class="reportBlank-relativeBg"
              alt=""
            />

            <edpWaterBg
              percent="20%"
              opacity="0.4"
              itemHeight="160px"
              slot="bgContent"
            />

            <img
              src="@/src/assets/reportCover/two/circle-bg.png"
              class="reportBlank-relativeBg2"
              alt=""
            />

            <img
              src="@/src/assets/reportCover/two/art2.png"
              class="reportBlank-relativeBg3"
              :class="{ selected: activeIndex == 2 }"
              alt=""
            />

            <div class="reportBlank-relativeBg4">
              <label
                ><span>{{ info.thisYear.toString().split("")[0] }}</span>
                <span>{{ info.thisYear.toString().split("")[1] }}</span>
                <span>{{ info.thisYear.toString().split("")[2] }}</span>
                <span>{{ info.thisYear.toString().split("")[3] }}</span></label
              >
              <div
                class="reportBlank-relativeBg4-detail"
                v-html="$t('reportCover.reportCover2Title')"
              ></div>
            </div>
          </div>

          <section class="reportBlank-commonContent reportBlank-detailContent2">
            <div class="reportBlank-center">
              <section
                class="an-reportCover2__conAc"
                :class="{ selected: activeIndex == 2 }"
              >
                <p>
                  <span v-html="$t('reportCover.slide3Content2')"></span>
                  <span class="wordsp-p">{{ info.memberTimeNum || 0 }}</span>
                  <span v-html="$t('reportCover.slide3Content3')"></span>
                  <span class="wordsp-p">{{ info.memberTimePerson || 0 }}</span>
                  <span v-html="$t('reportCover.slide3Content4')"></span>

                  <span
                    v-html="$t('reportCover.slide3Content1')"
                    v-if="info.memberDay"
                  ></span>
                  <span v-if="info.memberDay">{{ info.memberDay }}</span>

                  <span
                    class="wordsp-p"
                    v-if="info.memberDay && info.memberTime"
                    >{{ " " + info.memberTime }}</span
                  >
                </p>

                <p class="wdspt2">
                  <span v-html="$t('reportCover.slide3Content8')"></span>
                </p>

                <div
                  class="wdspt3"
                  :class="{
                    'an-op0':
                      !info.empYearMeetingCommons ||
                      info.empYearMeetingCommons.length == 0,
                  }"
                >
                  <img
                    :src="info.meetingCommonPicture || defaultHeadImage"
                    alt=""
                  />

                  <span class="wordsp-p">
                    {{
                      this.$i18n.locale === "cn"
                        ? info.meetingCommonName ||
                          info.meetingCommonEnglishName
                        : info.meetingCommonEnglishName ||
                          info.meetingCommonName
                    }}</span
                  >

                  <span v-html="$t('reportCover.slide3Content9')"></span>

                  <a href="javascript:void(0);" @click="memberDialogShow">{{
                    $t("reportCover.slide3Content10")
                  }}</a>
                </div>

                <p class="wdspt1">
                  <span v-html="$t('reportCover.slide3Content5')"></span>
                </p>

                <p class="wordsp2">
                  <span v-html="$t('reportCover.slide3Content6')"></span>
                  <br />
                  <span v-html="$t('reportCover.slide3Content7')"></span>
                </p>

                <div class="reportBlank-btnLine">
                  <a
                    href="javascript:void(0);"
                    class="an-reportCover2-mainButton"
                    @click="slideToNext"
                  >
                    {{ $t("reportCover.nextBtn") }}
                  </a>
                </div>
              </section>
            </div>
          </section>
        </div>
      </swiper-slide>
      <!--2 end-->

      <!--3-->
      <swiper-slide>
        <div class="reportBlank-slide">
          <div class="reportBlank-bg">
            <img
              src="@/src/assets/reportCover/two/bg.png"
              class="reportBlank-relativeBg"
              alt=""
            />

            <edpWaterBg
              percent="20%"
              opacity="0.4"
              itemHeight="160px"
              slot="bgContent"
            />

            <img
              src="@/src/assets/reportCover/two/circle-bg.png"
              class="reportBlank-relativeBg2"
              alt=""
            />

            <img
              src="@/src/assets/reportCover/two/art3.png"
              class="reportBlank-relativeBg3"
              :class="{ selected: activeIndex == 3 }"
              alt=""
            />

            <div class="reportBlank-relativeBg4">
              <label
                ><span>{{ info.thisYear.toString().split("")[0] }}</span>
                <span>{{ info.thisYear.toString().split("")[1] }}</span>
                <span>{{ info.thisYear.toString().split("")[2] }}</span>
                <span>{{ info.thisYear.toString().split("")[3] }}</span></label
              >
              <div
                class="reportBlank-relativeBg4-detail"
                v-html="$t('reportCover.reportCover2Title')"
              ></div>
            </div>
          </div>

          <section class="reportBlank-commonContent reportBlank-detailContent3">
            <div class="reportBlank-center">
              <section
                class="an-reportCover2__conAc"
                :class="{ selected: activeIndex == 3 }"
              >
                <p>
                  <span v-html="$t('reportCover.slide4Content1New')"></span>
                  <span class="wordsp-p">{{ info.memberOutNum || 0 }}</span>
                  <span v-html="$t('reportCover.slide4Content2New')"></span>
                </p>

                <p
                  class="wdspt2"
                  v-if="info.memberOutCity && info.memberOutDay"
                >
                  <span v-html="$t('reportCover.slide4Content3New')"></span>
                  <span class="wordsp-p">{{ info.memberOutCity }}</span>
                  <span v-html="$t('reportCover.slide4Content4New')"></span>
                  <span class="wordsp-p">{{ info.memberOutDay }}</span>
                  <span v-html="$t('reportCover.slide4Content5New')"></span>
                </p>

                <p>
                  <span v-html="$t('reportCover.slide4Content6New')"></span>
                </p>

                <p>
                  <span class="wordsp-p">{{ info.businessCity }}</span>
                </p>

                <p class="wdspt1">
                  <span v-html="$t('reportCover.slide4Content7')"></span>
                </p>

                <p class="wordsp2">
                  <span v-html="$t('reportCover.slide4Content8')"></span>
                  <br />
                  <span v-html="$t('reportCover.slide4Content9')"></span>
                </p>

                <div class="reportBlank-btnLine">
                  <a
                    href="javascript:void(0);"
                    class="an-reportCover2-mainButton"
                    @click="slideToNext"
                  >
                    {{ $t("reportCover.nextBtn") }}
                  </a>
                </div>
              </section>
            </div>
          </section>
        </div>
      </swiper-slide>
      <!--3 end-->

      <!--4-->
      <swiper-slide>
        <div class="reportBlank-slide">
          <div class="reportBlank-bg">
            <img
              src="@/src/assets/reportCover/two/bg.png"
              class="reportBlank-relativeBg"
              alt=""
            />

            <edpWaterBg
              percent="20%"
              opacity="0.4"
              itemHeight="160px"
              slot="bgContent"
            />

            <img
              src="@/src/assets/reportCover/two/circle-bg.png"
              class="reportBlank-relativeBg2"
              alt=""
            />

            <img
              src="@/src/assets/reportCover/two/art4.png"
              class="reportBlank-relativeBg3"
              :class="{ selected: activeIndex == 4 }"
              alt=""
            />

            <div class="reportBlank-relativeBg4">
              <label
                ><span>{{ info.thisYear.toString().split("")[0] }}</span>
                <span>{{ info.thisYear.toString().split("")[1] }}</span>
                <span>{{ info.thisYear.toString().split("")[2] }}</span>
                <span>{{ info.thisYear.toString().split("")[3] }}</span></label
              >
              <div
                class="reportBlank-relativeBg4-detail"
                v-html="$t('reportCover.reportCover2Title')"
              ></div>
            </div>
          </div>

          <section class="reportBlank-commonContent reportBlank-detailContent4">
            <div class="reportBlank-center">
              <section
                class="an-reportCover2__conAc"
                :class="{ selected: activeIndex == 4 }"
              >
                {{ $i18n.locale === "cn" ? "" : "In " }}{{ info.slideSpYear }}

                <p>
                  <span v-html="$t('reportCover.slide5Content1New')"></span>
                  <span class="wordsp-p">{{ info.memberProgramNum || 0 }}</span>
                  <span v-html="$t('reportCover.slide5Content2New')"></span>
                </p>

                <p>
                  <span v-html="$t('reportCover.slide5Content3New')"></span>

                  <span class="wordsp-p">{{
                    info.slideSpDay ? info.slideSpDay : 0
                  }}</span>
                  <span v-html="$t('reportCover.slide5Content2noBr')"></span>

                  <span class="wordsp-p">{{
                    info.slideSpHour ? info.slideSpHour : 0
                  }}</span>
                  <span v-html="$t('reportCover.slide5Content3')"></span>

                  <span v-html="' ' + $t('reportCover.slide5Content4')"></span>
                  <span class="wordsp-p">{{ info.slideSpPercent }}</span>
                  <span v-html="$t('reportCover.slide5Content5')"></span>
                </p>

                <p class="wdspt2">
                  <span v-if="info.slideSpDate">
                    <span>{{ info.slideSpDate }}</span>
                    <span v-html="$t('reportCover.slide5Content6')"></span>
                    <span class="wordsp-p wordsp-p-normal">{{
                      info.slideSpInTime
                    }}</span>
                    <span v-html="$t('reportCover.slide5Content7')"></span>
                  </span>

                  <span class="reportMbBr"><br /></span>

                  <span v-if="info.slideSpDate2">
                    <span>{{ info.slideSpDate2 }}</span>
                    <span v-html="$t('reportCover.slide5Content8')"></span>
                    <span class="wordsp-p wordsp-p-normal">{{
                      info.slideSpOutTime
                    }}</span>
                    <span v-html="$t('reportCover.slide5Content9')"></span>
                  </span>
                </p>

                <p class="wdspt1">
                  <span v-html="$t('reportCover.slide5Content10')"></span>
                </p>

                <p class="wordsp2">
                  <span
                    v-html="$t('reportCover.slide5Content11')"
                    style="margin-left: 0"
                  ></span>
                  <br />
                  <span v-html="$t('reportCover.slide5Content12')"></span>
                </p>

                <div class="reportBlank-btnLine">
                  <a
                    href="javascript:void(0);"
                    class="an-reportCover2-mainButton"
                    @click="slideToNext"
                  >
                    {{ $t("reportCover.nextBtn") }}
                  </a>
                </div>
              </section>
            </div>
          </section>
        </div>
      </swiper-slide>
      <!--4 end-->

      <!--5-->
      <swiper-slide>
        <div class="reportBlank-slide">
          <div class="reportBlank-bg">
            <img
              src="@/src/assets/reportCover/two/bg.png"
              class="reportBlank-relativeBg"
              alt=""
            />

            <edpWaterBg
              percent="20%"
              opacity="0.4"
              itemHeight="160px"
              slot="bgContent"
            />

            <img
              src="@/src/assets/reportCover/two/circle-bg.png"
              class="reportBlank-relativeBg2"
              alt=""
            />

            <img
              src="@/src/assets/reportCover/two/art5.png"
              class="reportBlank-relativeBg3"
              :class="{ selected: activeIndex == 5 }"
              alt=""
            />

            <div class="reportBlank-relativeBg4">
              <label
                ><span>{{ info.thisYear.toString().split("")[0] }}</span>
                <span>{{ info.thisYear.toString().split("")[1] }}</span>
                <span>{{ info.thisYear.toString().split("")[2] }}</span>
                <span>{{ info.thisYear.toString().split("")[3] }}</span></label
              >
              <div
                class="reportBlank-relativeBg4-detail"
                v-html="$t('reportCover.reportCover2Title')"
              ></div>
            </div>
          </div>

          <section class="reportBlank-commonContent reportBlank-detailContent5">
            <div class="reportBlank-center">
              <section
                class="an-reportCover2__conAc"
                :class="{ selected: activeIndex == 5 }"
              >
                {{ $i18n.locale === "cn" ? "" : "In " }}{{ info.slideSpYear }}

                <span v-html="$t('reportCover.slide6Content1_1')"></span>

                <span v-html="$t('reportCover.slide6Content1_2')"></span>

                <span class="wordsp-p">{{ info.relateActive || 0 }}</span>
                <span v-html="$t('reportCover.slide6Content2')"></span>

                <section
                  class="edp-reportCover__activePimg"
                  v-if="info.relateList && info.relateList.length > 0"
                >
                  <img
                    v-for="(item, index) in info.relateList"
                    alt=""
                    :key="'reportCover__activePimg' + index"
                    :src="item.image || defaultHeadImage"
                  />
                </section>

                <p
                  v-if="
                    info.relateList &&
                    info.relateList.length > 0 &&
                    $i18n.locale === 'en'
                  "
                >
                  <span v-html="$t('reportCover.slide6Content4')"></span>

                  <span class="wordsp-p">{{ info.relateActive2 }}</span>

                  <span v-html="$t('reportCover.slide6Content5')"></span>

                  <br />

                  <span v-html="$t('reportCover.slide6Content3')"></span>

                  <span
                    v-for="(item, index) in info.relateList"
                    :key="'reportCover_activePerson' + index"
                  >
                    {{ item.name + " " }}
                  </span>
                </p>

                <p
                  v-if="
                    info.relateList &&
                    info.relateList.length > 0 &&
                    $i18n.locale === 'cn'
                  "
                >
                  <span v-html="$t('reportCover.slide6Content3')"></span>

                  <span
                    v-for="(item, index) in info.relateList"
                    :key="'reportCover_activePerson' + index"
                  >
                    {{ item.name + " " }}
                  </span>

                  <br />

                  <span v-html="$t('reportCover.slide6Content4')"></span>

                  <span class="wordsp-p">{{ info.relateActive2 }}</span>

                  <span v-html="$t('reportCover.slide6Content5')"></span>
                </p>

                <div class="wdspt1" v-if="info.relateRecommend">
                  <span v-html="$t('reportCover.slide6Content6')"></span>
                  <span class="wordsp-p">{{ info.relateRecommend }}</span>
                  <span v-html="$t('reportCover.slide6Content7')"></span>
                  <span v-html="$t('reportCover.slide6Content8')"></span>
                </div>

                <div class="reportBlank-btnLine">
                  <a
                    href="javascript:void(0);"
                    class="an-reportCover2-mainButton"
                    @click="slideToNext"
                  >
                    {{ $t("reportCover.nextBtn") }}
                  </a>
                </div>
              </section>
            </div>
          </section>
        </div>
      </swiper-slide>
      <!--5 end-->

      <!--last-->
      <swiper-slide>
        <div class="reportBlank-slide">
          <div class="reportBlank-bg">
            <img
              src="@/src/assets/reportCover/two/sq-last.png"
              class="reportBlank-relativeBg"
              alt=""
            />

            <edpWaterBg
              percent="20%"
              opacity="0.4"
              itemHeight="160px"
              slot="bgContent"
            />

            <div class="reportBlank-relativeBg4">
              <label
                ><span>{{ info.thisYear.toString().split("")[0] }}</span>
                <span>{{ info.thisYear.toString().split("")[1] }}</span>
                <span>{{ info.thisYear.toString().split("")[2] }}</span>
                <span>{{ info.thisYear.toString().split("")[3] }}</span></label
              >
              <div
                class="reportBlank-relativeBg4-detail"
                v-html="$t('reportCover.reportCover2Title')"
              ></div>
            </div>
          </div>

          <section class="reportBlank-commonContent reportBlank-lastContent">
            <div class="reportBlank-center">
              <section
                class="an-reportCover2__conAc"
                :class="{ selected: activeIndex == 6 }"
              >
                <div class="lastPage-title">
                  <label
                    ><span>{{ info.thisYear.toString().split("")[0] }}</span>
                    <span>{{ info.thisYear.toString().split("")[1] }}</span>
                    <span>{{ info.thisYear.toString().split("")[2] }}</span>
                    <span>{{
                      info.thisYear.toString().split("")[3]
                    }}</span></label
                  >
                  <div
                    class="reportBlank-relativeBg4-detail"
                    v-html="$t('reportCover.reportCover2Title')"
                  ></div>
                </div>

                <div class="wdspt1">
                  <p>
                    {{ $i18n.locale === "cn" ? "" : "In " }}{{ info.thisYear }}
                  </p>

                  <p v-html="$t('reportCover.slide7Content1_1')"></p>
                </div>

                <div class="wdspt1">
                  <p>
                    {{ $i18n.locale === "cn" ? "" : "For " }}{{ info.nextYear }}
                  </p>

                  <p v-html="$t('reportCover.slide7Content1_2')"></p>
                </div>
              </section>
            </div>
          </section>
        </div>
      </swiper-slide>
      <!--last end-->
    </swiper>

    <edp-dialog
      ref="reportDialog"
      class="reportCover1-dialog-sp"
      overlayShow="no"
    >
      <div slot="content" class="reportCover1-dialog__article">
        <section class="reportCover1-article__title">
          {{ $t("reportCover.dialogMemberTitle") }}
        </section>

        <ul>
          <li
            v-for="(item, index) in info.empYearMeetingCommons"
            :key="'empYearMeetingCommons' + index"
          >
            <div class="reportCover1-article__detail">
              <section class="reportCover1-article__detail__img">
                <img
                  :src="item.meetingCommonPicture || defaultHeadImage"
                  alt=""
                />
              </section>

              <section class="reportCover1-article__detail__content">
                <div class="reportCover1-article__detail__title">
                  {{
                    $i18n.locale === "cn"
                      ? item.meetingCommonCname || item.meetingCommonEname
                      : item.meetingCommonEname || item.meetingCommonCname
                  }}

                  <em v-if="item.meetingCommonGenerations"
                    ><span>{{ item.meetingCommonGenerations }}</span></em
                  >
                </div>

                <p>
                  {{
                    $i18n.locale === "cn"
                      ? item.meetingCommonJobPositionCn ||
                        item.meetingCommonJobPosition
                      : item.meetingCommonJobPosition ||
                        item.meetingCommonJobPositionCn
                  }}
                </p>
              </section>
            </div>
          </li>
        </ul>
      </div>
    </edp-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "reportCover2",
  props: ["info", "percent"],
  data() {
    return {
      activeIndex: 0,
      swiperOptions: {
        direction: "vertical",
      },
      slideStatus: true,
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  methods: {
    onSlideChange() {
      this.activeIndex = this.$refs["mySwiper"].swiper.activeIndex;
    },
    slideToNext() {
      if (this.slideStatus) {
        let nextIndex = this.activeIndex + 1;

        this.$refs["mySwiper"].swiper.slideTo(nextIndex, 500, false);

        this.activeIndex = nextIndex;
      }
    },
    memberDialogShow() {
      this.$refs.reportDialog.show();
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.an-reportCover2 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 1.4;

  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .reportBlank-slide {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .reportBlank-bg {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }
  .reportBlank-relativeBg {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    z-index: 0;
  }
  .reportBlank-relativeBg2 {
    position: absolute;
    width: auto;
    height: 20%;
    right: 26%;
    bottom: 0;
    z-index: 1;
  }
  .reportBlank-relativeBg3 {
    position: absolute;
    width: auto;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 2;
    transform: translateX(100%);
    transition: transform 1s;

    &.selected {
      transform: translateX(0);
    }
  }
  .reportBlank-relativeBg4 {
    position: absolute;
    width: toPad(223);
    height: toPad(130);
    background: url("~@/src/assets/reportCover/two/circle-info.png") no-repeat 0
      0;
    background-size: 100% auto;

    right: 0;
    bottom: 0;
    z-index: 3;

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: toPad(70);
    padding-top: toPad(10);

    label {
      display: block;
      position: relative;
      font-size: toPad(42);
      font-weight: bold;
      z-index: 0;
      line-height: 1;
      font-family: JLREmeric;
      margin-left: toPad(12);

      span {
        position: relative;
        text-transform: uppercase;
        background: linear-gradient(
          133.19deg,
          rgba(255, 255, 255, 0.64) 24.02%,
          rgba(255, 255, 255, 0) 70.95%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left: toPad(-10);
      }
    }
    .reportBlank-relativeBg4-detail {
      position: relative;
      width: toPad(110);
      color: #fff;
      margin-top: toPad(-15);

      p {
        font-size: toPad(24);
        margin-top: toPad(-10);
      }
    }
  }

  .lastPage-title {
    position: relative;
    width: toPad(226);
    margin: 0 auto;

    label {
      display: flex;
      width: 100%;
      position: relative;
      font-size: toPad(110);
      font-weight: bold;
      z-index: 0;
      line-height: 1;
      font-family: JLREmeric;
      margin-left: toPad(26);

      span {
        position: relative;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.72) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left: toPad(-26);
      }
    }
    .reportBlank-relativeBg4-detail {
      position: relative;
      width: 100%;
      color: #fff;
      margin-top: toPad(-53);

      p {
        font-size: toPad(54);
        margin-top: toPad(-25);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.72) 0%,
          rgba(255, 255, 255, 0.72) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .reportBlank-center {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .reportBlank-btnLine {
    width: 100%;
    padding-top: toPad(36);

    .an-reportCover2-mainButton {
      font-size: toPad(20);
      color: #fff;
      padding: toPad(12) toPad(37);
    }
  }

  .reportBlank-commonContent {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 0 toPad(160);
    color: #fff;
    left: 0;
    top: 0;
  }
  .reportBlank-detailContent1 {
    .wdspt1 {
      display: block;
      width: toPad(520);
    }
    .wdspt2 {
      padding-top: toPad(20);
    }
  }

  .reportBlank-detailContent2 {
    .wdspt1 {
      display: block;
      width: 100%;
      padding: toPad(40) 0 toPad(20) 0;
    }
    .wdspt2 {
      padding-top: toPad(20);
    }
    .wdspt3 {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: toPc(10);

      img {
        display: block;
        border-radius: 100%;
        width: toPc(60);
        height: toPc(60);
        margin-right: toPc(18);
      }
      .wordsp-p {
        margin-right: toPc(6);
      }
      a:link,
      a:visited {
        display: inline-block;
        padding: toPc(10) toPc(20);
        line-height: 1;
        font-size: toPc(20);
        color: #fff;
        background: #b79663;
        border-radius: toPc(36);
        cursor: pointer;
        margin-left: toPc(15);
      }
    }
  }

  .reportBlank-detailContent3 {
    .wdspt1 {
      display: block;
      width: 100%;
      padding: toPad(50) 0 toPad(20) 0;
    }
    .wdspt2 {
      padding-top: toPad(20);
    }
  }

  .reportBlank-detailContent4 {
    .wdspt1 {
      display: block;
      width: 100%;
      padding: toPad(50) 0 toPad(20) 0;
    }
    .wdspt2 {
      padding-top: toPad(20);
    }
  }

  .reportBlank-detailContent5 {
    .wdspt1 {
      display: block;
      width: 100%;
      padding: toPad(30) 0 toPad(10) 0;
    }
  }
  .reportBlank-lastContent {
    .an-reportCover2__conAc {
      color: #fff;
    }
    .wdspt1 {
      display: block;
      width: 100%;
      padding: toPad(30) 0 0 0;
      text-align: center;
    }
  }

  .reportBlank-firstContent {
    padding: 0;

    .an-reportCover2__conAc {
      color: #fff;
      width: toPad(740);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .reportBlank-firstContent-year {
      position: relative;
      font-size: toPad(180);
      font-weight: bold;
      z-index: 0;
      line-height: 1;
      margin-left: toPad(42);
      font-family: JLREmeric;

      span {
        position: relative;
        text-transform: uppercase;
        background: linear-gradient(
          133.19deg,
          rgba(255, 255, 255, 0.64) 24.02%,
          rgba(255, 255, 255, 0) 70.95%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left: toPad(-42);
      }
    }
    .reportBlank-firstContent-title {
      position: relative;
      z-index: 1;
      font-size: toPad(76);
      margin-top: toPad(-80);

      .reportBlank-firstContent-wordDetail {
        p {
          margin-top: toPad(-44);
        }
      }
      &.cn-sp {
        .reportBlank-firstContent-wordDetail {
          p {
            margin-top: toPad(-40);
          }
        }
      }

      .reportBlank-firstContent-sp {
        font-size: toPad(30);
        margin-top: toPad(10);
      }
    }

    .reportBlank-firstContent-headImage {
      position: absolute;
      left: toPad(430);
      top: toPad(80);
      background: linear-gradient(
        90deg,
        rgba(164, 149, 122, 0.62) 11%,
        rgba(217, 217, 217, 0) 69.29%
      );
      border-radius: toPad(102) 0px 0px toPad(102);
      z-index: 2;
      width: toPad(610);
      height: toPad(190);
      display: flex;
      align-items: center;

      img {
        display: block;
        width: toPad(150);
        height: toPad(150);
        border-radius: 100%;
        margin-left: toPad(20);
        flex-shrink: 0;
      }
      .reportBlank-firstContent-headImage__detail {
        flex-basis: 100%;
        margin-left: toPad(30);

        p {
          font-size: toPad(20);
          color: rgba(255, 255, 255, 0.61);
        }
      }
    }

    .reportBlank-firstContent-bottomWord {
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: toPad(23);
      margin-top: toPad(40);

      .reportBlank-firstContent-bottomWord-sq {
        margin: 0 toPad(10);
      }
    }
    .reportBlank-firstContent-bottomLine {
      width: 100%;
      text-align: center;
      margin-top: toPad(20);
    }
  }

  .an-reportCover2-mainButton {
    display: inline-block;
    font-size: toPad(26);
    font-weight: bold;
    text-align: center;
    padding: toPad(12) toPad(37);
    line-height: 1;
    cursor: pointer;
    background: linear-gradient(92.75deg, #e6d6a5 4.52%, #bea261 100%);
    border-radius: toPad(100);
  }

  .an-reportCover2__conAc {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    color: #3f3939;
    font-size: toPad(26);
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 0.5s;

    &.selected {
      opacity: 1;
    }
  }
  .an-reportCover2__conAc__wordYellow1 {
    font-size: toPad(36);
    color: #c9b074;
  }

  .wordsp {
    font-size: 36px;
    font-weight: bold;
    color: #c9b074;
  }
  .wordsp-p {
    color: #c9b074;
    font-size: toPad(30);
  }
  .wordsp2 {
    color: #7a9da2;
    font-size: toPad(45);
    font-weight: bold;
    line-height: 1.1;
  }

  .edp-reportCover__activePimg {
    width: 100%;
    padding: toPc(30) 0 toPc(30) 0;
    overflow: hidden;

    img {
      display: block;
      float: left;
      margin-right: toPc(14);
      width: toPc(126);
      height: toPc(126);
      border-radius: 100%;
    }
  }
}

.reportCover1-dialog-sp {
  width: toPc(985) !important;
  background: linear-gradient(
    274.27deg,
    rgba(255, 255, 255, 0.69) 0.14%,
    rgba(255, 255, 255, 0.26) 46.74%,
    rgba(255, 255, 255, 0.78) 96.36%,
    rgba(255, 255, 255, 0.43) 96.36%
  );
  border: 4px solid rgba(255, 255, 255, 0.47);
  backdrop-filter: blur(17.5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: toPc(20);

  .reportCover1-dialog__article {
    width: 100%;
    padding-bottom: toPc(30);
  }
  .reportCover1-article__title {
    color: #000;
    width: 100%;
    text-align: center;
    font-size: toPc(26);
    font-weight: bold;
    padding-bottom: toPc(10);
  }
  ul {
    display: flex;
    width: 100%;
    max-height: 60vh;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;

    li {
      width: 33.33%;
      padding: toPc(25) toPc(25) 0 toPc(25);

      &:first-child {
        .reportCover1-article__detail__img {
          &::after {
            display: block;
            position: absolute;
            top: toPc(-20);
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            content: "";
            width: toPc(25);
            height: toPc(23);
            background: url("~@/src/assets/rank/rank-one.png") no-repeat 0 0;
            background-size: 100% 100%;
          }
          img {
            border: 2px solid #feb64e;
          }
        }
      }
    }
    .reportCover1-article__detail {
      display: flex;
      width: 100%;
    }
    .reportCover1-article__detail__img {
      position: relative;
      width: toPc(55);
      height: toPc(55);
      flex-shrink: 0;

      img {
        display: block;
        position: relative;
        width: toPc(55);
        height: toPc(55);
        border: 2px solid #ffffff;
        border-radius: 100%;
      }
    }
    .reportCover1-article__detail__content {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: toPc(15);
    }
    .reportCover1-article__detail__title {
      display: flex;
      font-size: toPc(18);
      align-items: center;

      em {
        display: inline-block;
        color: #fff;
        border-radius: toPc(8) 0px;
        background: linear-gradient(90.61deg, #60cfc6 3.65%, #61bfb4 99.54%);
        padding: toPc(2) toPc(8);
        text-align: center;
        margin-left: toPc(8);
        font-style: normal;
        border: 1px solid rgba(152, 244, 235, 0.6);
        line-height: toPc(16);
        transform: scale(0.86);

        span {
          font-size: toPc(14);
          transform: scale(0.86);
        }
      }
    }
    p {
      color: rgba(0, 0, 0, 0.5);
      padding-top: toPc(4);
      font-size: toPc(12);
      line-height: 1.4;
    }
  }
}
</style>
